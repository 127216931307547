.custom-material-select {

  .mat-form-field-appearance-outline .mat-form-field-outline-thick {
    color: transparent;
    height: 36px;
    border: 1px solid var(--orkestra-surface-01);
    background-color: var(--orkestra-background-01);
    border-radius: 5px;
    opacity: 1;
  }

  .material-select .mat-form-field-label-wrapper {
    padding: 0;
    top: 0;
  }

  .mat-form-field-appearance-outline .mat-form-field-wrapper {
    margin: 0 0 .25em 0;
  }

  .mat-form-field-infix {
    border-top: 0;
  }

  .mat-form-field-suffix {
    color: var(--orkestra-neutral-01);
  }

  .mat-form-field-appearance-outline .mat-form-field-suffix {
    top: 0.75em
  }

  .mat-form-field-appearance-outline .mat-form-field-outline-start,
  .mat-form-field-appearance-outline .mat-form-field-outline-gap,
  .mat-form-field-appearance-outline .mat-form-field-outline-end {
    border: 0;
  }

  .mat-form-field-label {
    color: var(--orkestra-neutral-03);
    font-family: $font-weight-regular;
    font-size: $heading-sm;
  }

  .mat-select-value {
    color: var(--orkestra-neutral-03);
    font-size: $text-md;
  }

  .mat-select-arrow {
    border: none;
  }

  .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float
  .mat-form-field-label,
  .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    -webkit-transform: none;
    transform: none;
    color: transparent;
  }
}

.details-select.mat-form-field-wrapper {
  margin: 0;
  padding: 0;
}

.details-select.mat-select-panel {
  max-width: 100%;
  width: auto;
  position: relative;
  background-color: var(--orkestra-background-02);
  color: var(--orkestra-neutral-03);
  border-radius: 5px;
}

.details-select .mat-option:hover {
  background: var(--orkestra-background-01);
  border-radius: 5px;
}

.details-select .mat-option-text {
  color: var(--orkestra-neutral-03);
  font-family: $font-weight-regular;
  font-size: $text-md;
}

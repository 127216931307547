.custom-dialog .mat-dialog-container {
  padding: 24px !important;
  background-color: var(--orkestra-background-02);
  -webkit-transition: background-color 0ms linear;
  -ms-transition: background-color 0ms linear;
  transition: background-color 0ms linear;

  &--message {
    background-color: var(--orkestra-surface-01);
  }
}

.custom-dialog .mat-dialog-content {
  overflow: visible !important;
  max-height: none !important;
}

.custom-light .mat-dialog-container {
  background-color: var(--orkestra-surface-01);
  min-width: 30vw;
  max-height: 85vh;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: var(
      --orkestra-background-02
    );
    border-radius: 10px;
  }
}

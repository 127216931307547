// position dropdown modal courier-tranfer
.mat-menu-panel {
  position: relative;
  top: 15px;
  left: 15px;
  font-family: 'Margem Rounded';
}

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: var(--orkestra-primary-01);
}

.mat-menu-item {
  font-family: 'Margem Rounded';
}

.courier-change {
  .mat-sort-header-container {
    justify-content: center;
  }
}

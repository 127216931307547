.date-picker-custom .mat-form-field-flex {
  background-color: transparent;
}

.date-picker-custom .mat-form-field-prefix .mat-icon-button,
.date-picker-custom .mat-form-field-suffix .mat-icon-button {
  color: var(--orkestra-highlight-02);
}

.mat-datepicker-content .mat-calendar {
  background-color: var(--orkestra-background-02);
}

.mat-datepicker-content {
  margin-top: 14px;
}

.mat-calendar-table-header {
  color: var(--orkestra-highlight-02);
}

.mat-calendar-table-header-divider {
  &::after {
    height: 0!important;
  }
}

.mat-calendar-body-cell-content,
.mat-date-range-input-separator {
  color: var(--orkestra-neutral-01);
}

.mat-calendar-arrow {
  border: none;
}

.mat-button,
.mat-icon-button,
.mat-stroked-button,
.mat-flat-button {
  color: var(--orkestra-on-primary-01);
}

.mat-calendar-body-label {
  color: var(--orkestra-on-primary-01);
  padding-top: 0!important;
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: var(--orkestra-on-primary-01);;
}

.mat-date-range-input-container {
  font-family: Margem Rounded;
  font-size: $heading-sm;
}

.mat-form-field-type-mat-date-range-input .mat-form-field-infix {
  width: 230px;
}

.mat-calendar-body-selected {
  color: var(--orkestra-on-primary-01);
  background-color: var(--orkestra-primary-01);
  box-shadow: 0 0 0 2px var(--orkestra-on-primary-01);
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: 0 0 0 2px var(--orkestra-on-primary-01);
}

.mat-form-field-prefix,
.mat-form-field-suffix {
  top: 6px;
}

.mat-datepicker-actions {
  background-color: var(--orkestra-background-02);
  justify-content: space-between!important;
  padding: 20px!important;
}

.msg-sucess {
    font-family: Margem Rounded;
    font-style: normal;
    background-color: var(--orkestra-success-02);
    color: var(--orkestra-on-success-01);
    .mat-button-wrapper {
        color: var(--orkestra-on-success-01);
    }
}

.msg-erro {
    font-family: Margem Rounded;
    font-style: normal;
    background-color: var(--orkestra-error-01);
    color: var(--orkestra-on-error-01);
    .mat-button-wrapper {
        color: var(--orkestra-on-error-01);
    }
}
// mat-form-field
.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0 0 9px 5px;
}

.mat-form-field-flex {
  font-family: Margem Rounded;
  padding: 0 .75em 0 .75em;
  background-color: var(--orkestra-background-02);
  border: 1px solid var(--orkestra-surface-01);
  color: var(--orkestra-neutral-03);
  box-sizing: border-box;
  border-radius: 5px;

  input {
    &::placeholder {
      color: var(--orkestra-neutral-03);
    }
  }
}

.mat-form-field-appearance-outline .mat-form-field-outline-start,
.mat-form-field-appearance-outline .mat-form-field-outline-end {
  border: 0px !important;
}

.mat-focused {
  border: 0px !important;
}

.mat-sort-header-container {
  justify-content: center;
}

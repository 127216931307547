@import 'abstracts/variables';

.cart-item-list {
  .mat-form-field-infix {
    padding: 5px 0px 11px;
  }

  .mat-form-field-wrapper {
    padding-bottom: 0px;
  }

  .mat-input-element {
    caret-color: var(--orkestra-neutral-02);
    font-family: Margem Rounded;
    font-style: normal;
    font-weight: 300;
    font-size: $text-md;
    line-height: $text-line-height;
    color: var(--orkestra-highlight-01);
  }

  .mat-form-field-label {
    font-size: $text-sm;
    color: var(--orkestra-neutral-02) !important;
  }

  .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
    border-width: 1px;
    color: var(--orkestra-error-01);
  }

  .mat-form-field.mat-form-field-invalid .mat-form-field-label {
    color: var(--orkestra-error-01);
  }

  .mat-form-field-appearance-outline .mat-form-field-outline {
    border-width: 1px;
    color: var(--orkestra-background-02);
  }
}

.mat-menu-panel {
  background-color: var(--orkestra-background-01);
  border-radius: 4px;
  border: 1px solid var(--orkestra-background-02);

  .mat-menu-content {
    padding: 0px !important;
  }

  .mat-menu-item {
    color: var(--orkestra-highlight-01);

    &:hover {
      background-color: var(--orkestra-background-02);
    }
  }

  .danger-opt.mat-menu-item {
    color: var(--orkestra-highlight-01);

    &:hover {
      background-color: var(--orkestra-error-02);
    }
  }

}

@import 'abstracts/variables';

.mat-checkbox {
  font-family: $font-weight-regular;
}

.mat-checkbox-inner-container {
  background-color: var(--orkestra-background-01);
  width: 20px!important;
  height: 19px!important;
  border: 1.8px solid var(--orkestra-neutral-03)!important;
  border-radius: 2px!important;
}

.mat-checkbox-frame {
  border: transparent;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: var(--orkestra-primary-04);
  width: 16px;
  height: 16px;
  top: 1.9px;
  left: 1.9px;
}

.mat-checkbox-checkmark-path {
  stroke: var(--orkestra-on-primary-01)!important;
}

.mat-checkbox-checkmark-path {
  stroke-width: 3.5!important;
}

.mat-checkbox-layout {
  white-space: normal!important;
}

// Required bootstrap files
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.select-custom {

  .mat-form-field-flex {
    background-color: transparent;
    border: none;
    box-sizing: border-box;
  }

  .mat-form-field-appearance-outline
  .mat-form-field-outline-thick {
    color: transparent;
    border: 1px solid var(--orkestra-surface-01);
    background-color: var(--orkestra-background-02);
    border-radius: 5px;
    opacity: 1;
  }

  .mat-form-field-appearance-outline
  .mat-form-field-infix {
    padding: 14px 0 14px 0;
    border-top: 6px solid transparent;
  }

  .mat-form-field-label {
    color: var(--orkestra-neutral-03);
    font-family: $font-weight-regular;
    font-size: $heading-sm;
    line-height: $heading-line-height;
  }

  .mat-select-value {
    color: var(--orkestra-neutral-03);
  }

  .mat-select-value-text {
    font-family: $font-weight-regular;
  }

  .mat-select-arrow {
    border: none;
  }

  .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float
  .mat-form-field-label,
  .mat-input-server:focus + .mat-form-field-label-wrapper
  .mat-form-field-label {
    -webkit-transform: none;
    transform: none;
    color: transparent;
  }

  .mat-form-field-label-wrapper {
    top: -12px;
  }

  &__material {

    &.mat-form-field-wrapper {
      margin: 0;
      padding: 0;
    }

    &.mat-select-panel {
      max-width: 100%;
      width: auto;
      position: relative;
      top: 50px;
      left: 4px;
      background-color: var(--orkestra-background-02);
      color: var(--orkestra-neutral-03);
      border-radius: 5px;
    }

    .mat-option:hover {
      background: var(--orkestra-background-01)!important;
      border-radius: 5px;
    }

    .mat-option-text {
      color: var(--orkestra-neutral-03)!important;
      font-family: $font-weight-regular;
      font-size: $text-md;
      line-height: $text-line-height;
    }
  }
}

//.cdk-overlay-pane {
//  transform: translateX(-16px) translateY(-15px)!important;
//}

@include media-breakpoint-down(xxl) {
  .select-custom{

    .mat-form-field-appearance-outline
    .mat-form-field-infix {
      padding: 10px 0px;
    }

    .mat-form-field-label {
      font-size: $heading-xs;
    }

    .mat-form-field-label-wrapper {
      top: -11px;
    }

    &__material {

      .select-custom__material.mat-select-panel {
        max-width: 525px;

        mat-option {
          white-space: normal;
          word-wrap: break-word;
        }
      }
    }
  }
}

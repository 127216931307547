/*
 * Variables allow us to reuse values without having to copy them over and over again. Most importantly, they make updating a value very easy.
 *  - only create variables when it makes sense to do so.
 *  - the value is repeated at least twice;
 *  - the value is likely to be updated at least once;
 *  - all occurrences of the value are tied to the variable
*/

//FONTS
$font-weight-regular: 'MargemRounded-Regular';
$font-weight-light: 'MargemRounded-Light';
$font-weight-medium: 'MargemRounded-Medium';
$font-weight-bold: 'MargemRounded-Bold';

/* Text sizes */

// Heading
$heading-lg: 24px;
$heading-md: 20px;
$heading-sm: 16px;
$heading-xs: 14px;
$heading-xxs: 12px;
$heading-line-height: 100%;

// Subheading
$subheading-lg: 24px;
$subheading-md: 20px;
$subheading-sm: 16px;
$subheading-xs: 14px;
$subheading-xxs: 12px;
$subheading-line-height: 100%;

// Text
$text-lg: 20px;
$text-md: 16px;
$text-sm: 14px;
$text-xs: 12px;
$text-line-height: 120%;

// Label
$label-lg: 20px;
$label-md: 16px;
$label-sm: 14px;
$label-xs: 12px;
$label-line-height: 100%;
$label-letter-spacing: 0.04em;

// Caption
$caption-size: 14px;
$caption-line-height: 100%;

//SPACE SIZES
$space-none: 0px;
$space-alpha: 5px;
$space-beta: 10px;
$space-gama: 15px;
$space-delta: 20px;
$space-epsilon: 25px;
$space-zeta: 30px;
$space-eta: 35px;

//BORDER RADIUS
$border-lambda: 100px;

//DESIGN SYSTEM (In progress)

$j-spacing-inline-mini: 8px;
$j-spacing-stack-xs: 32px;
$j-spacing-inline-xxs: 24px;

// Z-INDEX

$z-index-1: 100;
$z-index-2: 200;
$z-index-3: 300;
$z-index-4: 400;
$z-index-5: 500;
$z-index-6: 600;
$z-index-7: 700;
$z-index-8: 800;
$z-index-9: 900;
$z-index-10: 1000;



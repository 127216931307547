.cancellation-modal,
.reason-modal,
.order-split,
.duplicate-reasons,
.resend-reasons {
  &__types {
    .mat-radio-container{
      height: 16px;
      width: 16px;
    }

    .mat-radio-outer-circle{
      height: 16px;
      width: 16px;
      border: 1.4px solid var(--orkestra-neutral-02) !important;
    }

    .mat-radio-inner-circle{
      background-color: var(--orkestra-primary-04) !important;
      height: 16px;
      width: 16px;
    }

    .mat-ripple-element {
      background-color: var(--orkestra-primary-04) !important;
    }
  }
}

.maestro-info .mat-dialog-container {
  width: 1644px;
  height: 483px;
  padding-top: 0;
  padding-bottom: 32px;
  padding-left: 40px;
  padding-right: 24px;
  background-color: var(--orkestra-background-02);
  -webkit-transition: background-color 0ms linear;
  -ms-transition: background-color 0ms linear;
  transition: background-color 0ms linear;
}

.maestro-info .mat-dialog-content  {
  overflow: visible !important;
  max-height: none !important;
}

// mat-slide-toggle
.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: var(--orkestra-success-03);
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: var(--orkestra-success-01);
}

.mat-slide-toggle-bar,
.mat-slide-toggle-bar {
  background-color: var(--orkestra-neutral-03);
}

.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: var(--orkestra-success-01);
}

.mat-slide-toggle-content {
  font-size: $subheading-md;
  line-height: $heading-line-height;
  font-family: Margem Rounded;
}

// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.
// Required bootstrap files
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

// Optional bootstrap files
@import "~bootstrap/scss/grid";

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$james-operacao-primary: mat.define-palette(mat.$indigo-palette);
$james-operacao-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$james-operacao-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$james-operacao-theme: mat.define-light-theme($james-operacao-primary, $james-operacao-accent, $james-operacao-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($james-operacao-theme);

// Required bootstrap files
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

// Optional bootstrap files
@import "~bootstrap/scss/grid";

:root {
  /* Colors */

  // OrkestraPrimary
  --orkestra-primary-01: #874EE8;
  --orkestra-on-primary-01: #F6F5F7;

  --orkestra-primary-02: #5F37A2;
  --orkestra-on-primary-02: #F6F5F7;

  --orkestra-primary-03: #D8C6F8;
  --orkestra-on-primary-03: #5F37A2;

  --orkestra-primary-04: #A276ED;
  --orkestra-on-primary-04: #F6F5F7;

  // OrkestraSecondary
  --orkestra-secondary-01: #44E2EA;

  --orkestra-secondary-02: #0BAEB6;
  --orkestra-on-secondary-02: #F6F5F7;
  --orkestra-secondary-03: #0ED9E4;
  --orkestra-secondary-04: #0DC3CD;

  // OrkestraBackground
  --orkestra-background-01: #1B102E;

  --orkestra-background-02: #362D47;
  --orkestra-on-background-02: #DBD9DE;

  --orkestra-background-off-01: #1b102ea8;

  // OrkestraSurface
  --orkestra-surface-01: #524960;
  --orkestra-surface-02: #6D6679;
  --orkestra-surface-03: #362D47;
  --orkestra-surface-04: #1E1E1E;

  // OrkestraNeutral
  --orkestra-neutral-01: #888392;
  --orkestra-neutral-02: #A49FAB;
  --orkestra-neutral-03: #BFBCC5;
  --orkestra-neutral-04: #D9D9D9;
  --orkestra-neutral-05: #757575;
  --orkestra-neutral-06: #8a8d9680;
  --orkestra-neutral-07: #E1E2E9;

  // OrkestraHighlight
  --orkestra-highlight-01: #F6F5F7;
  --orkestra-highlight-02: #DBD9DE;

  // OrkestraSuccess
  --orkestra-success-01: #28DDA7;
  --orkestra-on-success-01: #F6F5F7;

  --orkestra-success-02: #24C796;
  --orkestra-success-03: #89ECCF;
  --orkestra-success-04: #CFF7D3;
  --orkestra-success-05: #02542D;
  --orkestra-success-06: #14AE5C;

  // OrkestraError
  --orkestra-error-01: #FC585E;
  --orkestra-on-error-01: #F6F5F7;

  --orkestra-error-02: #E34F55;
  --orkestra-on-error-02: #F6F5F7;

  --orkestra-error-03: #FFA8A8;
  --orkestra-on-error-03: #F6F5F7;

  --orkestra-error-03: #FDA3A7;
  --orkestra-on-error-03: #6D6679;

  // OrkestraWarning/OrkestraAttention
  --orkestra-warning-01: #FF8A58;
  --orkestra-on-warning-01: #F6F5F7;

  --orkestra-attention-01: #DDBA3E;
  --orkestra-on-attention-01: #F6F5F7;

  --orkestra-base: #FFFFFF;

  // OrkestraGradient
  --orkestra-gradient: linear-gradient(90deg, #6c3eba 0%, #874ee8 25%, #0ed9e4 75%, #0dc3cd 100%);

  // OrkestraShadow
  --orkestra-shadow-01: 27, 16, 46;

  // OrkestraBond
  --orkestra-bond-dark-01: #508ECC;
  
  //Fonts Family
  --font-family-margem-rounded: 'Margem Rounded';
}

* {
  -webkit-transition: background-color 500ms linear;
  -ms-transition: background-color 500ms linear;
  transition: background-color 500ms linear;
}


html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

:focus {
  outline: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input[type=search]::-webkit-search-cancel-button, input[type=search]::-webkit-search-decoration, input[type=search]::-webkit-search-results-button, input[type=search]::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none;
}

input[type=search] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}

audio, canvas, video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  max-width: 100%;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden] {
  display: none;
}

html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

a:focus {
  outline: thin dotted;
}

a:active, a:hover {
  outline: 0;
}

img {
  border: 0;
  -ms-interpolation-mode: bicubic;
}

figure {
  margin: 0;
}

form {
  margin: 0;
}

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  border: 0;
  padding: 0;
  white-space: normal;
  *margin-left: -7px;
}

button, input, select, textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
  *vertical-align: middle;
}

button, input {
  line-height: normal;
}

button, select {
  text-transform: none;
}

button, html input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
  *overflow: visible;
}

button[disabled], html input[disabled] {
  cursor: default;
}

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
  *height: 13px;
  *width: 13px;
}

input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
  vertical-align: top;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html, button, input, select, textarea {
  color: #222;
}

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

img {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

.chromeframe {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

body {
  max-height: 100vh;
  overflow: hidden;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@import 'abstracts/fonts';
@import 'abstracts/variables';
@import 'material-custom/material-custom';
.icon-10 {
  width: 10px !important;
  height: 10px !important;
  line-height: 10px !important;
}

.icon-12 {
  width: 12px !important;
  height: 12px !important;
  line-height: 12px !important;
}

.icon-16 {
  width: 16px !important;
  height: 16px !important;
  line-height: 16px !important;
}

.icon-24 {
  width: 24px !important;
  height: 24px !important;
  line-height: 24px !important;
}

.flex-spacer {
  flex: 1 1 auto;
}

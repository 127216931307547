@font-face {
  font-family: 'Margem-Black';
  src: url("/assets/fonts/Margem-Black.otf");
}

@font-face {
  font-family: 'Margem-BlackItalic';
  src: url("/assets/fonts/Margem-BlackItalic.otf");
}

@font-face {
  font-family: 'Margem-Bold';
  src: url("/assets/fonts/Margem-Bold.otf");
}

@font-face {
  font-family: 'Margem-BoldItalic';
  src: url("/assets/fonts/Margem-BoldItalic.otf");
}

@font-face {
  font-family: 'Margem-Italic';
  src: url("/assets/fonts/Margem-Italic.otf");
}

@font-face {
  font-family: 'Margem-Light';
  src: url("/assets/fonts/Margem-Light.otf");
}

@font-face {
  font-family: 'Margem-LightItalic';
  src: url("/assets/fonts/Margem-LightItalic.otf");
}

@font-face {
  font-family: 'Margem-Medium';
  src: url("/assets/fonts/Margem-Medium.otf");
}

@font-face {
  font-family: 'Margem-MediumItalic';
  src: url("/assets/fonts/Margem-MediumItalic.otf");
}

@font-face {
  font-family: 'Margem-Regular';
  src: url("/assets/fonts/Margem-Regular.otf");
}

@font-face {
  font-family: 'Margem-Thin';
  src: url("/assets/fonts/Margem-Thin.otf");
}

@font-face {
  font-family: 'Margem-ThinItalic';
  src: url("/assets/fonts/Margem-ThinItalic.otf");
}

@font-face {
  font-family: 'Margem-XBold';
  src: url("/assets/fonts/Margem-XBold.otf");
}

@font-face {
  font-family: 'Margem-XBoldItalic';
  src: url("/assets/fonts/Margem-XBoldItalic.otf");
}

@font-face {
  font-family: 'MargemRounded-Black';
  src: url("/assets/fonts/MargemRounded-Black.otf");
}

@font-face {
  font-family: 'MargemRounded-BlackItalic';
  src: url("/assets/fonts/MargemRounded-BlackItalic.otf");
}

@font-face {
  font-family: 'MargemRounded-Bold';
  src: url("/assets/fonts/MargemRounded-Bold.otf");
}

@font-face {
  font-family: 'MargemRounded-BoldItalic';
  src: url("/assets/fonts/MargemRounded-BoldItalic.otf");
}

@font-face {
  font-family: 'MargemRounded-Italic';
  src: url("/assets/fonts/MargemRounded-Italic.otf");
}

@font-face {
  font-family: 'MargemRounded-Light';
  src: url("/assets/fonts/MargemRounded-Light.otf");
}

@font-face {
  font-family: 'MargemRounded-LightItalic';
  src: url("/assets/fonts/MargemRounded-LightItalic.otf");
}

@font-face {
  font-family: 'MargemRounded-Medium';
  src: url("/assets/fonts/MargemRounded-Medium.otf");
}

@font-face {
  font-family: 'MargemRounded-MediumItalic';
  src: url("/assets/fonts/MargemRounded-MediumItalic.otf");
}

@font-face {
  font-family: 'MargemRounded-Regular';
  src: url("/assets/fonts/MargemRounded-Regular.otf");
}

@font-face {
  font-family: 'MargemRounded-Thin';
  src: url("/assets/fonts/MargemRounded-Thin.otf");
}

@font-face {
  font-family: 'MargemRounded-ThinItalic';
  src: url("/assets/fonts/MargemRounded-ThinItalic.otf");
}

@font-face {
  font-family: 'MargemRounded-XBold';
  src: url("/assets/fonts/MargemRounded-XBold.otf");
}

@font-face {
  font-family: 'MargemRounded-XBoldItalic';
  src: url("/assets/fonts/MargemRounded-XBoldItalic.otf");
}


/*
    100: Thin, Hairline, Ultra-light, Extra-light
    200: Light
    300: Book
    400: Regular, Normal, Plain, Roman, Standard
    500: Medium
    600: Semi-bold, Demi-bold
    700: Bold
    800: Heavy, Black, Extra-bold
    900: Ultra-black, Extra-black, Ultra-bold, Heavy-black, Fat, Poster
*/

@font-face {
  font-family: 'Margem';
  font-style: normal;
  font-weight: 800;
  src: url("/assets/fonts/Margem-Black.otf");
}

@font-face {
  font-family: 'Margem';
  font-style: italic;
  font-weight: 800;
  src: url("/assets/fonts/Margem-BlackItalic.otf");
}

@font-face {
  font-family: 'Margem';
  font-weight: bold;
  font-style: normal;
  src: url("/assets/fonts/Margem-Bold.otf");
}

@font-face {
  font-family: 'Margem';
  font-style: italic;
  font-weight: bold;
  src: url("/assets/fonts/Margem-BoldItalic.otf");
}

@font-face {
  font-family: 'Margem';
  font-weight: normal;
  font-style: italic;
  src: url("/assets/fonts/Margem-Italic.otf");
}

@font-face {
  font-family: 'Margem';
  font-style: normal;
  font-weight: 200;
  src: url("/assets/fonts/Margem-Light.otf");
}

@font-face {
  font-family: 'Margem';
  font-style: italic;
  font-weight: 200;
  src: url("/assets/fonts/Margem-LightItalic.otf");
}

@font-face {
  font-family: 'Margem';
  font-weight: 500;
  src: url("/assets/fonts/Margem-Medium.otf");
}

@font-face {
  font-family: 'Margem';
  font-weight: 500;
  font-style: italic;
  src: url("/assets/fonts/Margem-MediumItalic.otf");
}

@font-face {
  font-family: 'Margem';
  font-weight: 400;
  font-style: normal;
  src: url("/assets/fonts/Margem-Regular.otf");
}

@font-face {
  font-family: 'Margem';
  font-style: normal;
  font-weight: 100;
  src: url("/assets/fonts/Margem-Thin.otf");
}

@font-face {
  font-family: 'Margem';
  font-style: italic;
  font-weight: 100;
  src: url("/assets/fonts/Margem-ThinItalic.otf");
}

@font-face {
  font-family: 'Margem';
  font-weight: 900;
  src: url("/assets/fonts/Margem-XBold.otf");
}

@font-face {
  font-family: 'Margem';
  font-style: italic;
  font-weight: 900;
  src: url("/assets/fonts/Margem-XBoldItalic.otf");
}

@font-face {
  font-family: 'Margem Rounded';
  font-weight: 800;
  src: url("/assets/fonts/MargemRounded-Black.otf");
}

@font-face {
  font-family: 'Margem Rounded';
  font-style: italic;
  font-weight: 800;
  src: url("/assets/fonts/MargemRounded-BlackItalic.otf");
}

@font-face {
  font-family: 'Margem Rounded';
  font-weight: 700;
  src: url("/assets/fonts/MargemRounded-Bold.otf");
}

@font-face {
  font-family: 'Margem Rounded';
  font-weight: 700;
  font-style: italic;
  src: url("/assets/fonts/MargemRounded-BoldItalic.otf");
}

@font-face {
  font-family: 'Margem Rounded';
  font-weight: 400;
  font-style: italic;
  src: url("/assets/fonts/MargemRounded-Italic.otf");
}

@font-face {
  font-family: 'Margem Rounded';
  font-weight: 200;
  src: url("/assets/fonts/MargemRounded-Light.otf");
}

@font-face {
  font-family: 'Margem Rounded';
  font-style: italic;
  font-weight: 200;
  src: url("/assets/fonts/MargemRounded-LightItalic.otf");
}

@font-face {
  font-family: 'Margem Rounded';
  font-weight: 500;
  src: url("/assets/fonts/MargemRounded-Medium.otf");
}

@font-face {
  font-family: 'Margem Rounded';
  font-weight: 500;
  font-style: italic;
  src: url("/assets/fonts/MargemRounded-MediumItalic.otf");
}

@font-face {
  font-family: 'Margem Rounded';
  font-weight: 400;
  font-style: normal;
  src: url("/assets/fonts/MargemRounded-Regular.otf");
}

@font-face {
  font-family: 'Margem Rounded';
  font-weight: 100;
  src: url("/assets/fonts/MargemRounded-Thin.otf");
}

@font-face {
  font-family: 'Margem Rounded';
  font-style: italic;
  font-weight: 100;
  src: url("/assets/fonts/MargemRounded-ThinItalic.otf");
}

@font-face {
  font-family: 'Margem Rounded';
  font-weight: 800;
  src: url("/assets/fonts/MargemRounded-XBold.otf");
}

@font-face {
  font-family: 'Margem Rounded';
  font-style: italic;
  font-weight: 800;
  src: url("/assets/fonts/MargemRounded-XBoldItalic.otf");
}

// Required bootstrap files
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.actions-details-search {
    // mat-form-field
    .mat-form-field-appearance-outline .mat-form-field-infix {
      padding: 0 0 12px 10px;
      font-family: $font-weight-regular;
    }

    .mat-form-field-flex {
      background-color: var(--orkestra-background-02);
      border: 1px solid var(--orkestra-surface-01);
      box-sizing: border-box;
      border-radius: 5px;
    }

    .mat-form-field-appearance-outline .mat-form-field-outline-start,
    .mat-form-field-appearance-outline .mat-form-field-outline-end {
      border: 0px !important;
    }

    // mat-button-toggle
    .mat-button-toggle-label-content {
      line-height: 30px !important;
      margin-top: 4px;
      color: var(--orkestra-highlight-01);
    }
    // mat-button-toggle
    .mat-button-toggle-checked {
      .mat-button-toggle-label-content {
        color: var(--orkestra-on-primary-01);
      }
    }

    .mat-button-toggle-standalone.mat-button-toggle-appearance-standard {
      border: 0 !important;
      background-color: var(--orkestra-background-02);
  }
}

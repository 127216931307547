.courier-list-filter {
  // mat-form-field
  .mat-form-field-appearance-outline .mat-form-field-infix, .mat-button {
    padding: 0 0 9px 5px;
  }

  .mat-form-field-flex, .mat-button {
    background-color: var(--orkestra-background-01);
    border: 1px solid var(--orkestra-surface-01);
    box-sizing: border-box;
    border-radius: 5px;
  }

  .mat-form-field-appearance-outline .mat-form-field-outline-start,
  .mat-form-field-appearance-outline .mat-form-field-outline-end {
    border: 0px !important;
  }

  .mat-focused {
    border: 0px !important;
  }

  .select-custom__material .mat-button-wrapper{
    position: relative;
    top: 2px;
    left: 5px;
  }
}

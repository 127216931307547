.custom-radio {
  .mat-radio-outer-circle{
    border: 1.4px solid var(--orkestra-neutral-02) !important;
  }
  
  .mat-radio-inner-circle{
    background-color: var(--orkestra-primary-04) !important;
  }
  
  .mat-ripple-element {
    background-color: var(--orkestra-primary-04) !important;
  }
}
// mat-slide-toggle
.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: var(--orkestra-success-03);
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: var(--orkestra-success-01);
}

.mat-slide-toggle-bar,
.mat-slide-toggle-bar {
  background-color: var(--orkestra-neutral-03);
}

.mat-slide-toggle-content {
  font-size: $subheading-md;
  line-height: $heading-line-height;
  font-family: Margem Rounded;
}

@include media-breakpoint-down(xxl) {
  .mat-slide-toggle-content {
    font-size: $subheading-xxs !important;
    font-weight: normal;
  }
}

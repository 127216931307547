.resolve-modal {
  mat-checkbox {

    &.mat-checkbox-disabled .mat-checkbox-label {
      color: var(--orkestra-surface-02) !important;
    }

    &.mat-checkbox-checked.mat-accent .mat-checkbox-background {
      background: var(--orkestra-primary-04) !important;
    }

    & .mat-checkbox-inner-container {
      height: 18px;
      width: 18px;
      margin-right: 10px;
      background-color: var(--orkestra-background-01);
    }

    .mat-ripple-element {
      background: var(--orkestra-primary-04) !important;
    }

    & .mat-checkbox-frame {
      border: 1px solid var(--orkestra-background-01);
      border-radius: 2px;
    }
  }
}
